import React from 'react'

export default function Closes({width,fill,height,className}) {
    return (
            <svg className={className} width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.17499 7L13.425 1.75834C13.5819 1.60142 13.6701 1.38859 13.6701 1.16667C13.6701 0.944751 13.5819 0.731922 13.425 0.575002C13.2681 0.418082 13.0552 0.329926 12.8333 0.329926C12.6114 0.329926 12.3986 0.418082 12.2417 0.575002L6.99999 5.825L1.75832 0.575002C1.6014 0.418082 1.38857 0.329926 1.16666 0.329926C0.944739 0.329926 0.73191 0.418082 0.57499 0.575002C0.41807 0.731922 0.329914 0.944751 0.329914 1.16667C0.329914 1.38859 0.41807 1.60142 0.57499 1.75834L5.82499 7L0.57499 12.2417C0.496883 12.3191 0.434888 12.4113 0.392581 12.5129C0.350273 12.6144 0.328491 12.7233 0.328491 12.8333C0.328491 12.9433 0.350273 13.0523 0.392581 13.1538C0.434888 13.2554 0.496883 13.3475 0.57499 13.425C0.652459 13.5031 0.744627 13.5651 0.846176 13.6074C0.947725 13.6497 1.05665 13.6715 1.16666 13.6715C1.27667 13.6715 1.38559 13.6497 1.48714 13.6074C1.58869 13.5651 1.68085 13.5031 1.75832 13.425L6.99999 8.175L12.2417 13.425C12.3191 13.5031 12.4113 13.5651 12.5128 13.6074C12.6144 13.6497 12.7233 13.6715 12.8333 13.6715C12.9433 13.6715 13.0523 13.6497 13.1538 13.6074C13.2554 13.5651 13.3475 13.5031 13.425 13.425C13.5031 13.3475 13.5651 13.2554 13.6074 13.1538C13.6497 13.0523 13.6715 12.9433 13.6715 12.8333C13.6715 12.7233 13.6497 12.6144 13.6074 12.5129C13.5651 12.4113 13.5031 12.3191 13.425 12.2417L8.17499 7Z" fill={fill}/>
</svg>

    )
}

Closes.defaultProps ={
    width:'13.33px',
    height:'13.33px',
    fill:'#8F8F8F'
}
